
<div class="header">
    <div class="title">
        <app-event-edtcode v2="true" [asset]="asset" />
        {{asset.name}}
    </div>
</div>
<div class="content">
    <table class="properties">
        <tr>
            <td>Responsible</td>
            <td>
                <div class="owner">
                    <div [child-element]="ownerImage"></div>
                    <div>{{asset['_owner']?.name}}</div>
                </div>
            </td>
        </tr>

        <tr>
            <td>Start Date</td>
            <td>{{asset.startDate | date}}</td>
        </tr>
        <tr>
            <td>Target Date</td>
            <td>{{asset.endDate | date}}</td>
        </tr>
        <tr>
            <td>Status</td>
            <td>{{asset.status}}</td>
        </tr>
    </table>

    <div>
        <button mat-flat-button (click)="open()"><mat-icon>open_in_new</mat-icon>Open</button>
    </div>
</div>
