import { DatePipe } from '@angular/common';
import { Component, Inject, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { EventEdtcodeComponent } from 'src/app/components/event-edtcode/event-edtcode.component';
import { ChildElementDirective } from 'src/app/directives/child-element.directive';
import { getPrimaryAssociation, getUserProfileImageElement } from 'src/app/pages/pmo/helper';
import { AssetService } from 'src/app/services/asset.service';
import { TenantService } from 'src/app/services/tenant.service';
import { EDTCodePipe } from 'src/app/utils/pipes/edtcode.pipe';
import { Event_v1 } from 'src/dto/pmo/event-v1';

@Component({
    selector: 'app-event-preview',
    templateUrl: './event-preview.component.html',
    styleUrl: './event-preview.component.scss',
    imports: [
        DatePipe,
        MatButtonModule,
        MatIconModule,
        EDTCodePipe,
        ChildElementDirective,
        EventEdtcodeComponent
    ],
    standalone: true
})
export class EventPreviewComponent {
    @Input() asset: Event_v1;

    ownerImage: HTMLElement;

    constructor(
        @Inject(MAT_DIALOG_DATA) private readonly data: { data: { asset: Event_v1 }},
        private readonly assetService: AssetService,
        private readonly tenant: TenantService
    ) {
        this.asset = this.asset ?? data?.data?.asset;
    }

    ngOnInit() {
        const owner = getPrimaryAssociation(this.asset, window.tenant.users.value);
        this.ownerImage = getUserProfileImageElement(owner);
    }

    open() {
        this.assetService.openAssetPropertiesDialog(this.asset);
    }
}
