export const EDTIDList = [
    "EDTCLS",
    "EDTENV",
    "EDTEVT",
    "EDTLGE",
    "EDTAPP",
    "EDTGRP",
    "EDTUSR",
    "EDTCCG",
    "EDTFPT",
    "EDTNTZ",
    "EDTLOC",
    "EDTREG",
    "EDTPRV",
    "EDTALL",
    "EDTCLM",
    "EDTINV",
    "EDTORG",
    "EDTPOR",
    "EDTRTC",
    "EDTRTG",
    "EDTPRJ",
    "EDTGOL",
    "EDTOBJ",
    "EDTTSK",
    "EDTCGL",
    "EDTCOB",
    "EDTISS",
    "EDTRSK",
    "EDTPLF",
    "EDTCTK",
    "EDTRU",
    "EDTMTG",
    "EDTDTE"
]

export const DTOClassNameList = [
    "dynatrace-cluster",
    "dynatrace-environment",
    "event",
    "logical-environment",
    "application",
    "group",
    "user",
    "costcenter",
    "footprint",
    "networkzone",
    "location",
    "region",
    "provider",
    "observability-allocation",
    "observability-claim",
    "observable-inventory",
    "organization",
    "portfolio",
    "ratecard",
    "rategroup",
    "project",
    "goal",
    "objective",
    "task",
    "coverage-goal",
    "coverage-objective",
    "issue",
    "risk",
    "platform",
    "coverage-task",
    "resolvedUsers",
    "meeting",
    "dynatrace-event"
];

export const DTOClassNameToEDTID = {
    "dynatrace-cluster": "EDTCLS",
    "dynatrace-environment": "EDTENV",
    "event": "EDTEVT",
    "LogicalEnvironment": "EDTLGE",
    "application": "EDTAPP",
    "group": "EDTGRP",
    "user": "EDTUSR",
    "costcenter": "EDTCCG",
    "footprint": "EDTFPT",
    "networkzone": "EDTNTZ",
    "location": "EDTLOC",
    "region": "EDTREG",
    "provider": "EDTPRV",
    "observability-allocation": "EDTALL",
    "observability-claim": "EDTCLM",
    "observable-inventory": "EDTINV",
    "organization": "EDTORG",
    "portfolio": "EDTPOR",
    "ratecard": "EDTRTC",
    "rategroup": "EDTRTG",
    "project": "EDTPRJ",
    "goal": "EDTGOL",
    "objective": "EDTOBJ",
    "task": "EDTTSK",
    "coverage-goal": "EDTCGL",
    "coverage-objective": "EDTCOB",
    "issue": "EDTISS",
    "risk": "EDTRSK",
    "platform": "EDTPLF",
    "coverage-task": "EDTCTK",
    "resolvedUsers": "EDTRU",
    "meeting": "EDTMTG",
    "dynatrace-event": "EDTDTE"
};

export const EDTIDToDTOClassName = {
    "EDTCLS": "dynatrace-cluster",
    "EDTENV": "dynatrace-environment",
    "EDTEVT": "event",
    "EDTLGE": "LogicalEnvironment",
    "EDTAPP": "application",
    "EDTGRP": "group",
    "EDTUSR": "user",
    "EDTCCG": "costcenter",
    "EDTFPT": "footprint",
    "EDTNTZ": "networkzone",
    "EDTLOC": "location",
    "EDTREG": "region",
    "EDTPRV": "provider",
    "EDTALL": "observability-allocation",
    "EDTCLM": "observability-claim",
    "EDTINV": "observable-inventory",
    "EDTORG": "organization",
    "EDTPOR": "portfolio",
    "EDTRTC": "ratecard",
    "EDTRTG": "rategroup",
    "EDTPRJ": "project",
    "EDTGOL": "goal",
    "EDTOBJ": "objective",
    "EDTTSK": "task",
    "EDTCGL": "coverage-goal",
    "EDTCOB": "coverage-objective",
    "EDTISS": "issue",
    "EDTRSK": "risk",
    "EDTPLF": "platform",
    "EDTCTK": "coverage-task",
    "EDTRU": "resolvedUsers",
    "EDTMTG": "meeting",
    "EDTDTE": "dynatrace-event"
};

/**
 * Get the URI path for a given DTO Classname
 * May not be accurate
 */
export const DTOClassNameToUriPath = (className: string) => {
    return className.toLowerCase().replace(/_v1/, '');
}
