import { Injectable } from '@angular/core';

// These are the states that automation understands.
export const EventStates = [
    "New",
    "Progressing",
    "Review",
    "Done"
]

@Injectable({
    providedIn: 'root',
})
export class ActiveProjectService {
    eventStatusList: {
        label: string,
        key: string,
        state: "New" | "Hold" | "Progressing" | "Done",
        _collapsed?: boolean
    }[] = [
        { label: "New",         key: "New",         state: "New" },
        { label: "Open",        key: "Open",        state: "New" },
        { label: "On Hold",     key: "OnHold",      state: "Hold" },
        { label: "In Progress", key: "InProgress",  state: "Progressing" },
        { label: "In Review",   key: "Review",      state: "Progressing" },
        { label: "Closed",      key: "Close",       state: "Done", _collapsed: true }
    ];

    isValidStatus(status: string) {
        return !!this.eventStatusList.find(e => e.key == status);
    }

    constructor() {
        // TODO: Hydrate from "getaccount" transaction along with all of the other extra UI data.
    }

    ngOnInit() {

    }
}
