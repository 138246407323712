
@if (v2 == 'true') {
    <div class="prioRagEdt">
        @if (showEdtCode) {
            <span class="edtcode">
                {{asset | edtcode}}
            </span>
        }

        @if (asset.privacy == 1) {
            <mat-icon
                svgIcon="priv-dynatrace"
                [style.color]="theme.theme$.value == 'dark' ? '#eee' : '#111'"
            />
        }
        <div style="position: relative; height: 100%; display: flex; align-items: center;">
            <mat-icon
                svgIcon="prio-diamond"
                [style.color]="theme.theme$.value == 'dark' ? '#eee' : '#111'"
            />
            <div style="position: absolute; inset: 0; display: flex; justify-content: center;">
                <span
                    class="priority-level"
                    [style.color]="theme.theme$.value == 'dark' ? '#111' : '#eee'"
                    [style.fill]="ragColors[asset.rag] || 'var(--palette-gray-background-color)'"
                >
                    {{asset.priority || 0}}
                </span>
            </div>
        </div>
    </div>
}
@else {
    <div class="prioRagEdt">
        <mat-icon
            style="position: relative;"
            [svgIcon]="'prio-diamond'"
            [style.color]="theme.theme$.value == 'dark' ? '#eee' : '#111'"
            [style.fill]="ragColors[asset.rag] || 'var(--palette-gray-background-color)'"
        />
        <span
            class="prio-level {{asset.priority || 0}}"
            [style.color]="theme.theme$.value == 'dark' ? '#111' : '#eee'"
            style="z-index: 1;"
        >
            {{asset.priority || 0}}
        </span>

        @if (showEdtCode) {
            <span class="ragdot-{{asset.rag || 4}} edtcode">
                {{asset | edtcode}}
            </span>
        }
    </div>
}
